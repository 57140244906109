import { createStore } from 'vuex'
import functions from '../common/functions'
import createPersistedState from "vuex-persistedstate"

export default createStore({
    state: {
        wx_config: false,
        user_id: false,
        avatar: false,
        nickname: false,
        sex: false,
        token: false,
    },
    getters: {
    },
    mutations: {
        wxConfig(state, payload) {
            let debug = new functions.DEBUG('store commit')
            debug.log('init', { mutation: "wxConfig", old: state.wx_config })
            if (state.wx_config === false) {
                state.wx_config = {}
            }
            for (let key in payload) {
                state.wx_config[key] = payload[key]
            }
            debug.log('finish', { new: state.wx_config })
            debug.finish()
        },
        login(state, payload) {
            let debug = new functions.DEBUG('store commit')
            debug.log('init', { mutation: "login", old: { user_id: state.user_id, token: state.token } })
            state.user_id = payload.user_id
            state.token = payload.token
            debug.log('finish', { new: { user_id: state.user_id, token: state.token } })
            debug.finish()
        }
    },
    actions: {

    },
    modules: {
    },
    plugins: [createPersistedState({
        reducer(state) {
            return {

            }
        }
    })]

})
