import axios from 'axios'
import apis from './apis'
import config from '../common/config'
import functions from '../common/functions'
import store from '../store'
import { Notify, Dialog } from 'vant'
import qs from 'qs'

export default function f(api_name, data = {}) {
    let debug = new functions.DEBUG('api')
    let api = apis[api_name]
    if (api === undefined) {
        debug.log('failed', { api_name })
        debug.finish()
        throw '未定义的接口：' + api_name
    }
    let method = api.method
    let url = config.backend_url + api.url
    debug.log('init', { name: api_name, method, url })
    let params = api.token ? { user_id: store.state.user_id, token: store.state.token } : {}
    if (method == 'GET') {
        for (let key in data) {
            params[key] = data[key]
        }
    }
    return new Promise((resolve, reject) => {
        debug.log('running')
        axios({
            url,
            method,
            data,
            params,
            paramsSerializer: (params) => qs.stringify(params, { indices: false })
        }).then(res => {
            debug.log('finish', { http_status: res.status })
            if (res.status == 200) {
                resolve(res.data)
                debug.log('success', res.data)
            } else {
                reject(res.data)
                debug.log('failed', { http_status: res.status, detail: res.data.detail, message: res.data.message })
                Notify({ type: 'danger', message: `[${res.status}]接口调用错误：${res.data.message}` })
            }
            debug.finish()
        }).catch(res => {
            reject(res)
            res = res.response
            debug.log('failed', { http_status: res.status, detail: res.data.detail, message: res.data.message })
            Notify({ type: 'danger', message: `[${res.status}]接口调用错误：${res.data.message}` })
            debug.finish()
        })
    })
}
