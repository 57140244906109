const apis = {
    // getAppId: { interface: "appid", auto: [] },
    getAppId: { url: '/getAppId', method: 'GET', token: false },
    getJsapiSignature: { url: '/getJsapiSignature', method: 'POST', token: false },
    login: { url: '/login', method: 'POST', token: false },
    checkUser: { url: '/checkUser', method: 'GET', token: true },
    getBaseIssue: { url: '/getBaseIssue', method: 'GET', token: true },
    saveBaseInfo: { url: '/saveBaseIssue', method: 'POST', token: true },
    checkFace: { url: '/checkFace', method: 'POST', token: true },
    analyseFace: { url: '/analyseFace', method: 'POST', token: true },
    checkTongue: { url: '/checkTongue', method: 'POST', token: true },
    analyseTongue: { url: '/analyseTongue', method: 'POST', token: true },
    checkVoice: { url: '/checkVoice', method: 'POST', token: true },
    analyseVoice: { url: '/analyseVoice', method: 'POST', token: true },
    getQuestionnaire: { url: '/getQuestionnaire', method: 'GET', token: true },
    analyseIssue: { url: '/analyseIssue', method: 'POST', token: true },
    analyseIssueType: { url: '/analyseIssueType', method: 'POST', token: true },
    submitQuestionnaireResult: { url: '/submitQuestionnaireResult', method: 'POST', token: true },
    mainAnalyse: { url: '/mainAnalyse', method: 'GET', token: true },
    mainAnalyseType: { url: '/mainAnalyseType', method: 'GET', token: true },
    getReport: { url: '/getReport', method: 'GET', token: true },
    getHistoryReportList: { url: '/getHistoryReportList', method: 'POST', token: true },
    getSingleSubjectReport: { url: '/getSingleSubjectReport', method: 'POST', token: true },
    singleSubjectAnalyse: { url: '/singleSubjectAnalyse', method: 'POST', token: true },
    getList: { url: '/getList', method: 'POST', token: true },


    // login: { interface: "login", fields: ["code"], auto: [] },
    // getJsTicket: { interface: "jsticket", fields: ["url", "nonce_str", "timestamp"], auto: [] },
    // checkBaseInfo: { interface: "checkbaseinfo", method: 'POST' },
    // saveBaseInfo: { interface: "baseresult", fields: ['result'], method: 'POST' },
    // checkface: { interface: "checkface", fields: ["media_id"], method: "POST" },
    // analysis: { interface: "analysis", fields: ["media_id"], method: "POST" },
    // getBaseIssue: { interface: "baseissue", method: "POST" },
    // getOrganIssue: { interface: "organissue", method: "POST" },
    // submitOrganResult: { interface: "organissueresult", fields: ['result'], method: "POST" },
    // getCorporeityIssue: { interface: "tzissue", method: "POST" },
    // submitCorporeityResult: { interface: "tzissueresult", fields: ['result'], method: "POST" },
    // getHealthInfo: { interface: "healthinfo", method: "POST" },
}
export default apis