import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import config from './common/config'
import common from './common/functions'

let app = createApp(App)

import { Button, Toast, Image, Tab, Tabs, Field, RadioGroup, Radio, Cell, CellGroup, Picker, Popup, NoticeBar } from 'vant';
import { Checkbox, CheckboxGroup, Icon, DatetimePicker, Step, Steps, Progress, Tag, Collapse, CollapseItem ,Dialog,Cascader,Empty   } from 'vant';
app.use(Button).use(Toast).use(Image).use(Tab).use(Tabs).use(Field).use(Radio).use(RadioGroup).use(Icon).use(Step);
app.use(Cell).use(CellGroup).use(Picker).use(Popup).use(DatetimePicker).use(Checkbox).use(CheckboxGroup).use(Steps);
app.use(Progress).use(Tag).use(Collapse).use(CollapseItem).use(NoticeBar).use(Dialog).use(Cascader).use(Empty);

import Particles from "particles.vue3";
app.use(Particles)

app.config.globalProperties.$api = api;
app.config.globalProperties.$common = common;
app.config.globalProperties.config = config;

app.use(store).use(router).mount('#app')
