import moment from "moment"

const config = {
    backend_urls: {
        default: "https://brain.kaiboo.ltd",
        real_host: "http://172.16.1.185:5000",
    },
    frontend_url: "https://brain.kaiboo.ltd",
    backend_url:"https://tcmx.kaiboo.ltd/tcmx/api",
    mock: false,
    debug: true,
    start_time: new moment()
}
export default config