<template>
    <router-view />
</template>

<script>
export default {
    created() {
        this.$api("getAppId").then((res) => {
            this.$store.commit("wxConfig", { app_id: res.app_id });
        });
    },
};
</script>

<style></style>
