import moment from "moment"
import config from "../common/config"
import store from '../store'
import api from '../api'
// import wx from 'weixin-jsapi'
import wx from 'weixin-js-sdk'


/**
 * 一个DUBUG类，在开启debug时会在控制台输出相关信息
 */
class DEBUG {
    constructor(name) {
        this.flag = config.debug
        this.mark = randomString(4)
        this.start_time = moment()
        this.name = name.toLocaleUpperCase()
        this.left_length = parseInt((50 - name.length) / 2)
        this.right_length = this.left_length + (name.length % 2)
        let timestamp = (moment().diff(config.start_time) / 1000).toFixed(3).toString().replace('.', ':')
        if (this.flag) console.log(`[${this.mark} - 0ms - ${timestamp}] ${'-'.repeat(this.left_length)} ${this.name} DEBUG START ${'-'.repeat(this.right_length)}`)
    }
    log(state, data) {
        for (let key in data) {
            let timestamp = (moment().diff(config.start_time) / 1000).toFixed(3).toString().replace('.', ':')
            if (this.flag) console.log(`[${this.mark} - ${moment().diff(this.start_time)}ms - ${timestamp} - ${state}] ${key}:`, data[key])
        }
    }
    finish() {
        let timestamp = (moment().diff(config.start_time) / 1000).toFixed(3).toString().replace('.', ':')
        if (this.flag) console.log(`[${this.mark} - ${moment().diff(this.start_time)}ms - ${timestamp}] ${'-'.repeat(this.left_length + 1)} ${this.name} DEBUG END ${'-'.repeat(this.right_length + 1)}`)
    }
}
/**
 * 微信JS-SDK类，实例化后再调用相关方法
 */
class WX {
    constructor() {
        this.init()
    }
    async init() {
        let debug = new DEBUG("WX JSSDK INIT")
        let app_id = store.state.wx_config.app_id
        while (app_id === undefined) {
            await sleep(20)
        }
        console.log(app_id);
        debug.log("reday", { app_id })
        let url = location.href.split('#')[0]
        let nonce_str = randomString(32)
        let timestamp = parseInt(new Date().getTime() / 1000)
        let res = await api("getJsapiSignature", { url, nonce_str, timestamp })
        wx.config({
            // debug: config.debug,
            appId: app_id,
            timestamp,
            nonceStr: nonce_str,
            signature: res.signature,
            openTagList: ["wx-open-launch-weapp"], // 填入打开小程序的开放标签名
            jsApiList: ["chooseImage", "uploadImage", "startRecord","navigateTo", "stopRecord", "uploadVoice","miniProgram","wx-open-launch-weapp"]
        })
        wx.ready(() => {
            debug.log("finish")
            debug.finish()
        })
        wx.error((e) => {
            console.error(e)
            debug.log("failed")
            debug.finish()
        })
    }
    async selectImage(count) {
        let debug = new DEBUG("WX SELECT IMAGE")
        while (this.init === false) {
            await sleep(20)
        }
        return new Promise((resolve, reject) => {
            wx.chooseImage({
                count,
                sizeType: ['original'],
                sourceType: ['album'],
                success: (res) => { resolve(res.localIds[0]) },
                cancel: reject,
                complete: () => { debug.finish() }
            })
        })
    }
    async shootImage() {
        let debug = new DEBUG("WX SHOOT IMAGE")
        while (this.init === false) {
            await sleep(20)
        }
        return new Promise((resolve, reject) => {
            wx.chooseImage({
                count: 1,
                sizeType: ['original'],
                sourceType: ['camera'],
                success: (res) => { resolve(res.localIds[0]) },
                cancel: reject,
                complete: () => { debug.finish() }
            })
        })
    }
    async uploadImage(local_id) {
        let debug = new DEBUG("WX UPDATE IMAGE")
        return new Promise((resolve, reject) => {
            wx.uploadImage({
                localId: local_id,
                isShowProgressTips: 0,
                success: (res) => {
                    console.log('1', res)
                    resolve(res.serverId)

                },
                fail: (err) => {
                    console.log('2', err)
                    reject(err)
                },
                complete: () => { debug.finish() }
            })
        })
    }
    async startRecord() {
        let debug = new DEBUG("WX BEGAN RECORD")
        while (this.init === false) {
            await sleep(20)
        }
        return new Promise((resolve, reject) => {
            wx.startRecord({
                success: resolve,
                fail: reject,
                complete: () => { debug.finish() }
            })
        })
    }
    async stopRecord() {
        let debug = new DEBUG("WX STOP RECORD")
        return new Promise((resolve, reject) => {
            wx.stopRecord({
                success: res => resolve(res.localId),
                fail: reject,
                complete: () => { debug.finish() }
            })
        })
    }
    async uploadRecord(local_id) {
        let debug = new DEBUG("WX UPLOAD RECORD")
        return new Promise((resolve, reject) => {
            wx.uploadVoice({
                localId: local_id,
                isShowProgressTips: 0,
                success: res => resolve(res.serverId),
                fail: reject,
                complete: () => { debug.finish() }
            })
        })
    }
    // 跳回小程序
   onUniapp(atype){
        wx.miniProgram.reLaunch({
            url: `/pages/bluetooth_index/bluetooth_index?atype=${atype}&sta=true`, //小程序地址
          });
    }
}

function randomString(length = 4, map = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ') {
    let str = ''
    for (let i = 0; i < length; i++) {
        let rand = Math.random() * map.length
        str = str + map[parseInt(rand)]
    }
    return str;
}

async function sleep(time) {
    return new Promise((resolve) => {
        setTimeout(resolve, time)
    })
}

export default {
    DEBUG,
    WX,
    randomString,
    sleep
}